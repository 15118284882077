body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  touch-action: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Map Resets */

.uploaded-img {
  width: 80px;
  margin-left: 10px;
}

.canvas-container {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

g#CopyrightAnatomyMapper {
  visibility: hidden !important;
  pointer-events: none !important;
}

#HG-Oral_Anatomy,
#G-Oral_Anatomy {
  display: none;
}

.image-container {
  max-height: 100px;
  /* min-width: 140px; */
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.image-container img {
  margin-bottom: 10px;
  margin-top: 10px;
}

.upload-container {
  margin-top: 15px;
}

.image-list-textfield.image-list-textfield {
  padding: 0px 10px;
}

.image-list-item {
  display: flex;
}

.images-box {
  display: flex;
  overflow: auto;
}
.listContent__Images {
  display: block;
  width: 100%;
}

.svg-located-pin {
  display: inline-block;
  width: 150px;
  height: 150px;
  border: 2px solid #000;
  position: absolute;
  top: 0;
  left: 0;
}

.active-path-x {
  fill: rgb(141, 235, 137) !important;
  opacity: 1 !important;
  /* z-index: 1300; */
}

.active-path-x-hierarchy {
  fill: rgb(255, 0, 0) !important;
  opacity: 1 !important;
  /* z-index: 1300; */
}

@media screen and (max-width: 400px) {
  .images-box {
    max-width: 360px;
  }
  .hide_on_mobile {
    display: none;
  }
}
@media screen and (min-width: 400px) and (max-width: 500px) {
  .images-box {
    max-width: 400px;
  }

  .hide_on_mobile {
    display: none;
  }
}
@media screen and (min-width: 500px) and (max-width: 600px) {
  .images-box {
    max-width: 500px;
  }

  .hide_on_mobile {
    display: none;
  }
}
@media screen and (min-width: 600px) and (max-width: 700px) {
  .images-box {
    max-width: 600px;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .images-box {
    max-width: 700px;
  }
}
@media screen and (min-width: 800px) and (max-width: 950px) {
  .images-box {
    max-width: 800px;
  }
}
@media screen and (min-width: 950px) and (max-width: 1050px) {
  .images-box {
    max-width: 340px;
  }
}
@media screen and (min-width: 800px) and (max-width: 900px) {
  .images-box {
    max-width: 800px;
  }
}
@media screen and (min-width: 800px) and (max-width: 900px) {
  .images-box {
    max-width: 800px;
  }
}
@media screen and (min-width: 800px) and (max-width: 900px) {
  .images-box {
    max-width: 800px;
  }
}

.Pin-Point-Marker {
  touch-action: none;
  pointer-events: none;
}

.flash-animation {
  animation: clickedMarkerAnimation infinite ease-in 0.2s;
}

@keyframes clickedMarkerAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
