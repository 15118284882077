.k-split-button,
.k-button-group {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    margin: 5px;
    padding-bottom: 0 !important;
    /* min-width: 188.68px; */
}

.k-split-button > .k-button:first-child {
    background-color: #0c27c8 !important;
    color: #fff;
    margin: 0 !important;
    width: 85%;
}
.k-split-button > .k-button:last-child, .k-split-button > .k-split-button-arrow {
    background-color: #0c27c8 !important;
    color: #fff;
    margin: 0 !important;
    width: 15%;
}

.app__mainBody__list__buttons Button {
    margin: 5px;
    background-color: #0c27c8;
    color: #fff;
    text-transform: inherit;
    word-spacing: -1px;
}