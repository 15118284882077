* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

.app__nav {
  width: 100%;
  height: 70px;
  background-color: #e1e1e1;
  display: flex;
  align-items: center;
  padding: 6px;
  padding-left: 10px; }
  .app__nav__logo {
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    margin-right: 50px; }
    .app__nav__logo img {
      height: 85%; }
  .app__nav__switch__label {
    font-size: 10px;
    padding-right: 3px;
    width: 85px; }
  .app__nav__divider {
    height: 100%;
    border-right: 1px solid black; }
  .app__nav__listSelect {
    padding-left: 15px;
    display: flex;
    align-items: center; }
    .app__nav__listSelect__SVG {
      margin-right: 3px; }
    .app__nav__listSelect__label {
      padding-right: 5px; }
    .app__nav__listSelect__switch {
      height: 60px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ffffff; }
      .app__nav__listSelect__switch label {
        margin-right: 0px !important; }
      .app__nav__listSelect__switch .list__btn {
        position: relative;
        width: 100px;
        height: 100%;
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.5s ease-in-out; }
        .app__nav__listSelect__switch .list__btn .dropdown {
          display: none; }
          .app__nav__listSelect__switch .list__btn .dropdown.showDropdown {
            display: block; }
        .app__nav__listSelect__switch .list__btn.list__btn__selector {
          height: 100%;
          position: absolute;
          z-index: 0;
          background-color: #0c27c8;
          left: 0px;
          width: 170px;
          transition: all 0.5s ease-in-out; }
          .app__nav__listSelect__switch .list__btn.list__btn__selector.unordered.procedures {
            left: 100px; }
          .app__nav__listSelect__switch .list__btn.list__btn__selector.unordered.diagnosis {
            left: 200px; }
          .app__nav__listSelect__switch .list__btn.list__btn__selector.diagnosis {
            left: 300px; }
          .app__nav__listSelect__switch .list__btn.list__btn__selector.comments {
            left: 400px; }
        .app__nav__listSelect__switch .list__btn .MuiInput-underline:before {
          border-bottom: 1px solid rgba(0, 0, 0, 0.42); }
        .app__nav__listSelect__switch .list__btn.selected {
          width: 170px;
          color: #ffffff;
          transition: all 0.5s ease-in-out; }
          .app__nav__listSelect__switch .list__btn.selected .MuiInput-underline:before {
            border-bottom: 1px solid rgba(255, 255, 255, 0.8); }
          .app__nav__listSelect__switch .list__btn.selected:hover .MuiInput-underline:before {
            border-bottom: 2px solid white; }
          .app__nav__listSelect__switch .list__btn.selected select {
            color: white;
            transition: all 0.5s ease-in-out; }
            .app__nav__listSelect__switch .list__btn.selected select option {
              color: rgba(0, 0, 0, 0.87); }
          .app__nav__listSelect__switch .list__btn.selected svg {
            fill: white; }
  .app__nav__language {
    margin-left: auto; }
    .app__nav__language .MuiInput-underline:before {
      border-bottom: 1px solid rgba(0, 0, 0, 0.42); }

@media (max-width: 960px) {
  .app__nav__logo {
    margin-right: 20px; }
  .app__nav__listSelect {
    padding-left: 10px; }
    .app__nav__listSelect__label {
      font-size: 14px; }
    .app__nav__listSelect__switch > .list__btn {
      width: 110px;
      font-size: 14px; }
      .app__nav__listSelect__switch > .list__btn.list__btn__selector.diagnosis {
        left: 330px; }
      .app__nav__listSelect__switch > .list__btn.list__btn__selector.unordered.procedures {
        left: 110px; }
      .app__nav__listSelect__switch > .list__btn.list__btn__selector.unordered.diagnosis {
        left: 220px; }
      .app__nav__listSelect__switch > .list__btn.list__btn__selector.comments {
        left: 440px; }
  .app__nav__language__dropdown {
    max-width: 100px !important; } }

.app__mainBody {
  display: flex;
  align-items: flex-start; }
  .app__mainBody__SVGMapper {
    width: 65vw;
    height: calc(100vh - 70px - 25px); }
    .app__mainBody__SVGMapper > div {
      width: 100%;
      height: 100%; }
  .app__mainBody__list {
    flex: 1;
    padding: 0 5px;
    -ms-overflow-style: none;
    scrollbar-width: none; }
    .app__mainBody__list_with-scrollbar {
      height: calc(100vh - 70px);
      overflow-y: auto;
      overflow-x: hidden; }
    .app__mainBody__list::-webkit-scrollbar {
      display: none; }
    .app__mainBody__list__patient__info__list {
      padding-bottom: 5px;
      margin-top: 15px; }
    .app__mainBody__list__accordion__list {
      padding-bottom: 5px; }
    .app__mainBody__list__header > .MuiAccordionSummary-content {
      align-items: center; }
    .app__mainBody__list__header .MuiSvgIcon-root {
      fill: #fff !important; }
    .app__mainBody__list__body {
      padding: 0px !important; }
      .app__mainBody__list__body .list__reorder {
        padding: 4px;
        margin-left: auto;
        margin-right: 5px;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid #b6bde7;
        cursor: pointer;
        transition: all 0.5s ease-in-out; }
        .app__mainBody__list__body .list__reorder.descendingList {
          background-color: #b6bde7;
          transition: all 0.5s ease-in-out; }
        .app__mainBody__list__body .list__reorder.emptyList {
          display: none; }
    .app__mainBody__list__buttons {
      padding: 10px 5px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center; }
      .app__mainBody__list__buttons Button {
        margin: 5px 10px;
        background-color: #0c27c8;
        color: #fff;
        text-transform: inherit;
        word-spacing: -1px; }
        .app__mainBody__list__buttons Button:hover {
          background-color: #1d273d; }
  .app__mainBody__exportList {
    margin-top: 50px;
    display: none;
    justify-content: center; }
    .app__mainBody__exportList.showList {
      display: flex; }

@media (max-width: 1484px) {
  .app__mainBody__SVGMapper {
    width: 55w; }
  .app__mainBody__list {
    width: 45w; } }

@media (max-width: 960px) {
  .app__mainBody {
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .app__mainBody__SVGMapper {
      width: 100vw;
      height: calc(100vh - 245.7px - 70px); }
    .app__mainBody__list {
      padding-top: 10px;
      width: 100vw;
      height: 245.7px; }
      .app__mainBody__list__accordion {
        display: flex;
        justify-content: space-between;
        flex-direction: column; }
        .app__mainBody__list__accordion__list {
          width: 100% !important; } }

.listContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .listContent:not(.listContent:last-child) {
    border-bottom: 2px solid lightgray; }
  .listContent__basicInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
    .listContent__basicInfo p {
      font-size: 14px;
      line-height: 1;
      display: inline-block;
      flex: 1; }
    .listContent__basicInfo__anatomic_name span {
      display: inline-block;
      padding: 2.5px; }
      .listContent__basicInfo__anatomic_name span .MuiPaper-root {
        display: none; }
      .listContent__basicInfo__anatomic_name span:hover .MuiPaper-root {
        display: flex; }
    .listContent__basicInfo__anatomic_name .type-laterality {
      text-decoration: underline;
      font-style: italic; }
    .listContent__basicInfo__anatomic_name .type-prefix,
    .listContent__basicInfo__anatomic_name .type-suffix {
      font-style: italic; }
    .listContent__basicInfo__anatomic_name .type-name {
      font-weight: 900; }
    .listContent__basicInfo__anatomic_name .type-research {
      display: none; }
    .listContent__basicInfo__anatomic_name .type-order {
      font-style: italic;
      font-size: 12px;
      font-weight: 600; }
    .listContent__basicInfo__dropdown {
      padding: 0px !important;
      width: max-content !important;
      height: 40px !important; }
      .listContent__basicInfo__dropdown .MuiOutlinedInput-input:focus {
        background-color: transparent !important; }
  .listContent__additionalInputs {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 20px; }
    .listContent__additionalInputs > div {
      width: calc(50% - 20px); }
  .listContent__additionalMetaData {
    background-color: #999;
    color: #000;
    padding: 14px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
    width: calc(100% - 60px);
    margin: 20px auto;
    cursor: pointer; }
    .listContent__additionalMetaData a:visited, .listContent__additionalMetaData a:link {
      color: #1f3acf;
      margin-left: 10px; }
  .listContent__userData {
    padding-right: 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .listContent__userData div {
      display: flex;
      align-items: center; }
      .listContent__userData div span {
        font-size: 12px; }
  .listContent .MuiIconButton-root {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: fit-content !important; }
