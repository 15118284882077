.color-picker-container {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
}

.color-picker-overlay {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  inset: 0;
}

.color-picker {
  background-color: white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;
  text-align: right;
}

.color-picker .sketch-picker {
  box-shadow: none !important;
  padding-top: 0 !important;
}

.color-picker-container .color-picker .color-picker-close {
  fill: black !important;
}
